import type { SearchResultsResponse, SearchSuggestionsResponse } from 'types/search';

export default function () {
  function getSearchResults(term: string): Promise<SearchResultsResponse> {
    return UseBaseApi<SearchResultsResponse>('api/search', {
      params: {
        term
      },
      keepalive: true
    });
  }

  function getSearchSuggestions(term: string): Promise<SearchSuggestionsResponse> {
    return UseBaseApi<SearchSuggestionsResponse>('api/search/suggestions', {
      params: {
        term
      },
      keepalive: true
    });
  }

  return {
    getSearchResults,
    getSearchSuggestions
  };
}
